import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";
import { Fade } from "react-reveal";
import imgPartnerMayura from "../images/partner-mayura.webp";
import imgPartnerBananaLeaf from "../images/partner-banana-leaf.webp";
import imgPartnerShamrock from "../images/partner-shamrock.webp";
import imgPartnerHyderabadHouse from "../images/partner-hyderabad-house.webp";
import imgPartnerKoyla from "../images/partner-koyla.webp";
import imgPartnerDesiChowrastha from "../images/partner-desi-chowrastha.webp";
import imgOldeCity from "../images/partner-olde-city.webp";
import imgBiryaniNation from "../images/partner-biryani-nation.webp";

const items = [
  {
    title: "Olde City",
    img: imgOldeCity,
    link: "https://www.loveoldecity.com/",
  },
  {
    title: "Shamrocks Pub & Grill",
    img: imgPartnerShamrock,
    link: "https://www.shamrocks-pubandgrill.com/",
  },
  {
    title: "Mayura Indian Restaurant",
    img: imgPartnerMayura,
    link: "https://mayura-indian-restaurant.com/",
  },
  {
    title: "Hyderabad House",
    img: imgPartnerHyderabadHouse,
    link: "https://hhrtp.net/",
  },
  {
    title: "Banana Leaf Indian Restaurant",
    img: imgPartnerBananaLeaf,
    link: "https://bananaleafla.com/",
  },
  {
    title: "Koyla",
    img: imgPartnerKoyla,
    link: "https://koylarestaurant.com/",
  },
  {
    title: "Desi Chowrastha",
    img: imgPartnerDesiChowrastha,
    link: "https://www.desichowrastha.com/",
  },
  {
    title: "Desi Chowrastha",
    img: imgBiryaniNation,
    link: "https://biryaninationusa.com/",
  },
];

const OurCustomers = () => {
  return (
    <Container sx={{ py: 10, mb: 5 }}>
      <Typography
        variant="h4"
        component="h2"
        sx={{ textAlign: "center", mb: 3 }}
      >
        Our customers
      </Typography>
      <Box
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.15)",
          backgroundColor: "#fff",
          borderRadius: "15px",
          p: 2,
          alignItems: "center",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          overflow: "hidden",
          display: "flex",
        }}
      >
        {items.map((item, index) => (
          <Fade up key={index} delay={200 * index}>
            <Link href={item.link} target="_blank">
              <Box
                component="img"
                src={item.img}
                sx={{ width: 250, height: 150 }}
                alt={item.title}
              />
            </Link>
          </Fade>
        ))}
      </Box>
    </Container>
  );
};

export default OurCustomers;
